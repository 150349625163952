<template>
  <div class = "stage">
    <div class = "documentBlock" @click = "handleClick">
      <img draggable="false" :id = "'s'+item.manga_id" :src="imgSrc" style="width: 240px; height: 320px; object-fit: contain" >
      <p class = "showName">{{ item.manga_name }}</p>
      <p class = "showName">{{ item.artist_name }}</p>
      <p class = "showName">最新话名 ：{{ item.newest_epi }}</p>
    </div>
  </div>

</template>

<script>


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Document",
  props:["item"],
  data() {
    return {
      imgSrc:"",

    }
  },
  created() {
    this.imgSrc = 'data:image/png;base64,' + this.item.thumbnail;
  },
  mounted() {

  },

  methods: {

    commonCheck(res){
      if(res.code === 200){
        this.$notify({
          title: `${this.item.manga_name} 已入库`,
          message: `成功选择 ${this.item.manga_name}。 ID为 ${this.item.manga_id}。已开始在后台下载！`,
          type: 'success',
          position: 'bottom-right'
        });
        this.$emit("reload");
        return true
      }
      else if(res.code === 410){
        this.$notify({
          title: `${this.item.manga_name} 已存在在库中`,
          message: `请仔细检查后再添加！`,
          type: 'error',
          position: 'bottom-right'
        });
        return true
      }
      else if(res.code === 411){
        return false
      }
    },

    handleClick() {
       let info = `<p>漫画ID为 ： <strong>${this.item.manga_id}</strong></p>` +
           `<p>漫画作者为 ： <strong>${this.item.artist_name}</strong></p>` +
           `<p>该漫画最新话名为 ： <strong>${this.item.newest_epi}</strong></p>` +
           `<p>请检查上方信息是否正确，最好在漫画狗网站内核实一下</p>`+
           `<p><strong>并且添加的并非库中已有的漫画！</strong></p>`;
      let submitLoading;
      this.$confirm(info, `你选择的是 ${this.item.manga_name}`, {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
            submitLoading = this.$loading({
              lock: true,
              text: '正在提交下载请求，等待响应...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post("dogemanga/confirm",{
           manga_object: this.item,
           submit_sign : "0",
        }).then((data) =>{
          let res = data.data;
          submitLoading.close();
          let result = this.commonCheck(res);
         if(!result){
            let info = `<p>你选择的漫画名为 ： <strong>${this.item.manga_name}</strong></p>` +
                `<p>库中可能重复的漫画 ：</p>` +
                `<strong>${res.data}</strong>` +
                `<p>请再次核实并确认是否添加该漫画</p>`+
                `<p><strong>请勿添加库中已有的漫画！</strong></p>`;
            this.$confirm(info, `我们查了你的户籍，发现有可能的重复！`, {
              dangerouslyUseHTMLString: true,
              confirmButtonText: '仍要添加',
              cancelButtonText: '遗憾离场',
            }).then(() => {
              const submitLoading = this.$loading({
                lock: true,
                text: '正在提交下载请求，等待响应...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              this.$http.post("dogemanga/confirm",{
                manga_object: this.item,
                submit_sign : "1",
              }).then((data) => {
                    let res = data.data;
                    submitLoading.close();
                    this.commonCheck(res);
              })
            })
        }})
            }
        ).catch((error) => {
          submitLoading.close();
          this.isSearch = false;
          if(this.$route.fullPath !== "/") {
            this.$router.replace("/");
          }
          this.$message.error('漫画搜索出现未知问题，请联系Van！ Code:' + error.message);
        });
    },

    reload() {
      this.$emit("reload");
    },

    changeHeight(event){
      let c = event.target;
      let doc = c.parentNode;

      if(c.clientHeight !== 435){
        c.style.gridTemplateRows = "1fr 1fr 1fr 1fr";
        doc.style.transition = "all 0s";
      }
      doc.style.height = c.clientHeight + "px";



    },
    recover(event){
      let c = event.target;
      let doc = c.parentNode;
      doc.style.height = "100%";

      c.style.gridTemplateRows = "1fr 1fr";
      doc.style.transition = "all 0.3s ease-in-out";

    },


  },

}
</script>

<style scoped>
@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

@keyframes light {
  0% {filter: hue-rotate(0deg);}
  50% {filter: hue-rotate(90deg);}
  100% {filter: hue-rotate(0deg);}
}

.documentBlock{
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #252830;
  display: flex;
  flex-direction: column;
  justify-items:center;
  align-items:center;
  transition: all 0.3s ease-in-out;
}
.stage{
  position: relative;
  padding: 3px;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #252830;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content:center;
  align-items: center;
}
.stage:hover{
  z-index: 99999;
  transform: scale(1.05);
}
.stage::before {
  content: "";
  width: 103%;
  height: 102%;
  z-index: -1;
  position: absolute;

  border-radius: 8px;
  background-image: linear-gradient(
      var(--rotate)
      , #5ddcff, #3c67e3 43%, #4e00c2);
  top: -1%;
  left: -1.5%;

  animation: none;
  opacity: 0;
  transition: opacity 0.3s;
}
.stage::after {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: scale(0.9);
  filter: blur(calc(435px / 6));
  background-image: linear-gradient(
      var(--rotate)
      , #5ddcff, #3c67e3 43%, #4e00c2);

  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
  animation: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.stage:hover:before {
  animation: spin 2.5s linear infinite;
  opacity: 1;
}
.stage:hover:after {
  animation: spin 2.5s linear infinite;
  opacity: 1;
}
@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
.stage:hover .documentBlock{
  height: auto;
  min-height: 435px;
  max-height: fit-content;
}
.showName{
  color: white;
  margin: 2px;
  width: 80%;
  text-align: center;
  font-size:0.95em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  word-break: break-word;
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.stage:hover #showName{
  overflow: visible;
  text-overflow: unset;
  display: block;
}


</style>

