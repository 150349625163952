<template>
  <div id="uploadTable">

    <el-table
        :data="tableData.filter(data => !search || data.manga_name.toLowerCase().includes(search.toLowerCase())).sort((a,b) => {return b.add_date - a.add_date})"
        class = "grandTable"
        height="100%"
        :stripe = "true"
        :row-style="{background:'transparent',color:'white'}"
        empty-text = "暂时还没有漫画哦"
    >
      <el-table-column
          label="封面"
          prop= "manga_name"
          width="200">
        <template v-slot:default="scope">
          <img :id = "'s'+scope.row.manga_id" :src="'data:image/png;base64,' + scope.row.thumbnail" style="width: 100px; height: 133px; object-fit: contain" >
        </template>
      </el-table-column>
      <el-table-column
          label="漫画名"
          prop= "manga_name"
          width="240">
      </el-table-column>
      <el-table-column
          label="漫画ID"
          prop="manga_id"
          width="90">
      </el-table-column>
      <el-table-column
          label="作者"
          prop="artist_name"
          width="110">
      </el-table-column>
      <el-table-column
          label="当前库中最新话名称"
          prop="last_epi_name"
          width="240">
      </el-table-column>
      <el-table-column
          label="现在状态"
          prop="completed"
          width="90">
        <template slot-scope="scope">
          <span>{{ scope.row.completed === true ? '已完成' : (scope.row.completed === 1 ? '正在抓取...' : '排队中...') }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="right">
        <!-- eslint-disable-->
        <template v-slot:default="scope">
          <el-button type="danger" @click = "openReload(scope.row)">设置重新抓取</el-button>
        </template>
        <template v-slot:header="scope">
          <el-input
              v-model="search"
              size="mini"
              prefix-icon="el-icon-search"
              style="width: 200px"
              placeholder="输入关键字搜索"
          />
        </template>
      </el-table-column>
    </el-table>
    <MangaReloadForm @reload = "reloadPass" @closeNewDir = "closeNewDir" :newVisible = "newVisible" :manga_id="selectedMangaId" :manga_name="selectedMangaName"></MangaReloadForm>
  </div>
</template>

<script>
import MangaReloadForm from "@/components/MangaReloadForm.vue"
export default {
  name: "MangaKu",
  components : {
    MangaReloadForm
  },
  data(){
    return{
      search:"",
      tableData:[],
      newVisible:false,
      selectedMangaId: null,
      selectedMangaName: null,
    }
  },

  methods:{
    reloadPass(){
      this.$emit("reload");
    },
    openReload(row){
      this.selectedMangaId = row.manga_id;
      this.selectedMangaName = row.manga_name;
      this.newVisible = true;
    },
    closeNewDir(){
      this.searchFlag = false;
      this.editData = null;
      this.newVisible = false;
    },
  },

  async mounted() {
    const loading = this.$loading({
      lock: true,
      text: '正在加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    // eslint-disable-next-line no-undef
    OverlayScrollbars(document.querySelectorAll("#uploadTable .el-table__body-wrapper"), {
      className: 'os-theme-thick-light',
      scrollbars: { autoHide : "none",},
    });


    this.tableData = [],

    await this.$http.post("dogemanga/lib",).then((data) =>{
      let res = data.data;
      if(res.code === 200){
        for(let el of res.data){
          this.tableData.push(el);
        }
        this.tableData.sort(function compare(a,b){return b.add_date - a.add_date});
        loading.close();
      }
    }).catch((error) => {
      loading.close();
      this.isSearch = false;
      if(this.$route.fullPath !== "/") {
        this.$router.replace("/");
      }
      this.$message.error('漫画库加载出现未知问题，请联系Van！ Code:' + error.message);
    });

    await this.$http.get("dogemanga/cdl",).then((data) =>{
      let res = data.data;
      if(res.code === 200){
        let id = res.data;
        for(let el of this.tableData){
          if(el.manga_id === id){
            el.completed = 1;
          }
        }
      }
    }).catch((error) => {
      loading.close();
      this.isSearch = false;
      if(this.$route.fullPath !== "/") {
        this.$router.replace("/");
      }
      this.$message.error('漫画库下载加载出现未知问题，请联系Van！ Code:' + error.message);
    });




  },
  sockets: {
    //有人@我
    response(data){
      let id = data.manga_id;
      for(let el of this.tableData){
        if(el.manga_id === id){
          el.last_epi_name = data.newest_epi_name;
          el.last_epi = data.newest_epi;
          break;
        }
      }
    },

    complete_info(data){
      let id = data.manga_id;
      for(let el of this.tableData){
        if(el.manga_id === id){
          el.completed = true;
          this.$notify({
            title: `${el.manga_name} 完成所有下载！`,
            message: `${el.manga_name} 已经完成所有现有下载任务！等待后续循环更新`,
            type: 'success',
            position: 'bottom-right'
          });
          break;
        }
      }
    },

    downloading_info(data){
      let id = data;
      for(let el of this.tableData){
        if(el.manga_id === id){
          el.completed = 1;
          el.add_date = (Date.now() / 1000);
          this.$notify({
            title: `${el.manga_name} 开始抓取下载！`,
            message: `${el.manga_name} 开始初次抓取！`,
            type: 'success',
            position: 'bottom-right'
          });
          break;
        }
      }
    },


  },
  create() {
  },
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
}

.grandTable{
  width: 100%;
  height: 100%;
  background: #252830;
  position: absolute;
}

#uploadTable{
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 550px;
}
/deep/ .el-input__inner {
  background-color: rgba(255, 255, 255, 0.247);
  color: white;
}


#uploadTable >>> .el-table th{
  background:#444857;
  color:white;
}

#uploadTable >>> .el-table tr{
  background:#444857;
  color:white;
}

#uploadTable >>> .el-table th.gutter{   /*解决el-table加了gutter后 边框出现白边*/
  background:#444857;
}

#uploadTable >>> .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background-color: #34343f;
}

#uploadTable >>> .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #A85DC3;

}
#uploadTable >>> .el-table--enable-row-hover .el-table__body tr.el-table__row--striped:hover>td.el-table__cell {
  background-color: #A85DC3;

}
#uploadTable >>> .el-table__row>td,#uploadTable >>>  .el-table th.is-leaf{
  border: none;
}

</style>