<template>
  <el-container id = "main">
    <el-header class="frame header">
      <img alt="Vue logo" src="../assets/naiveLogo.png" id="logo">
      <p id = "logoText">请问你想来点漫画吗？</p>
    </el-header>
    <el-container class="mainPart">

        <!--@select ="attedCheck"-->
        <el-menu :default-active="this.$route.fullPath"
                 class="frame aside"
                 :collapse="isCollapse"
                 :router = "true"

                 background-color="#1e1f26"
                 text-color="#fff"
                 active-text-color="#ffd04b">

          <el-menu-item index="/mainpage/searchpage">
            <i class="bi-search menuIcons"></i>
            <span slot="title">搜索添加漫画</span>
          </el-menu-item>
          <el-menu-item index="/mainpage/mangaku">
            <i class="bi-tools menuIcons"></i>
            <span slot="title">现有漫画</span>
          </el-menu-item>



        </el-menu>
      <el-main class="frame main">
        <router-view v-if="isRouterAlive" @reload = "reload"></router-view>
      </el-main>
    </el-container>
    <Announcement class = "announcement"></Announcement>
    <v-pull-button :class = "!isCollapse ? 'mainPagePullButton' : 'mainPagePullButton hide' " :isHide = "isCollapse" @switchCollapse = "handleCollapse"></v-pull-button>
  </el-container>
</template>

<script>

import VPullButton from "@/components/V-pullButton";
import Announcement from "@/components/Announcement"


export default {

  name: "MainPage",
  data(){
    return{
      isRouterAlive: true,
      isCollapse: false,
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  methods:{

    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },

    handleCollapse(){
      this.isCollapse = !this.isCollapse;
    },
  },
  components:{
    VPullButton,
    Announcement,
  },
  sockets:{
    scan_completed(){
      this.$notify({
        title: `全盘扫描完成`,
        message: `已完成全盘扫描，建议检查后台以确定是否有无法扫描的漫画！`,
        type: 'warning',
        position: 'bottom-right'
      });
    }
  },
  created() {
    this.$socket.open();
    console.log("链接启动！");
  }
}
</script>

<style scoped>
#main{
  width: 100%;
  min-width: max-content;
  position: relative;
  height: 100%;
  min-height: 750px;
  background: linear-gradient(to right,#1e1f26 25%,black 100%);

}

.frame {
  padding: 0;


}

.frame.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-image: url("../assets/imgs/ccc.gif"),url("../assets/imgs/sparklesBig.gif"),linear-gradient(to right, rgba(17, 7, 12, 0.7) 10%,#06007E3F 20%, #06007E3F 90%, rgba(17, 7, 12, 0.7) 100%);
  animation: rotate 15s linear infinite , glowRotate 15s linear infinite;
  background-size: 50%;
  background-blend-mode: overlay;
  border-radius: 0 0 15px 15px;
  z-index: 2;
}
.mainPart{
  width: 100%;
  height: calc(100vh - 60px);

}

@keyframes glowRotate {
  0% {
    filter: drop-shadow(-15px -10px 10px #f0f) drop-shadow(-15px -10px 10px #324949);
  }
  25% {
    filter: drop-shadow(-15px -10px 20px #f0f) drop-shadow(-15px -10px 20px #324949);
  }
  50% {
    filter: drop-shadow(-15px -10px 10px #0080ff) drop-shadow(-15px -10px 10px #324949);
  }
  75% {
    filter: drop-shadow(-15px -10px 20px #0080ff) drop-shadow(-15px -10px 20px #324949);
  }
  100% {
    filter: drop-shadow(-15px -10px 10px #f0f) drop-shadow(-15px -10px 10px #324949);
  }
}
@keyframes rotate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.frame.main{
  max-height: 100%;
  background: #252830;
  position: relative;
  overflow: hidden;
}

.frame.aside{

  border: none;

  height: 100%;
  font-family: "AaGothic (Non-Commercial Use)";

  z-index: 1;
}

.frame.aside /deep/ .el-menu-item{
  font-size: 16px;
}
.frame.aside /deep/ .el-submenu__title{
  font-size: 16px;
}

#logo{
  padding: 0 25px;
  width: 150px;
  height: auto;
  filter:invert(100%);
  cursor: pointer;
}
#logoText{
  font-size: 1.5em;
  margin: 0px 20px;
  font-family: "AaGothic (Non-Commercial Use)";
  color: white;
}

.menuIcons{
  margin-right: 5px;
  width: 24px;
  font-size: 18px;
}

.frame.aside:not(.el-menu--collapse) {
  width: 220px;
  min-height: 550px;
}
.mainPagePullButton{
  position: absolute;
  left: 160px;
  bottom: 20px;
  z-index: 0;
  transition: all 0.3s ease-in-out;
}
.mainPagePullButton.hide{
  left: 4px;

}

.announcement{
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
